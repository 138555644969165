
const Cform = () => {
  return (
    <>
      <div class="sec-box contact section-padding bord-thin-top" data-scroll-index="5">
                <div class="row">
                    <div class="col-lg-5">
                        <div class="sec-head md-mb80 wow fadeIn" style={{visibility: "visible", animationName: "fadeIn"}}>
                            <h6 class="sub-title mb-15 opacity-7">Get In Touch</h6>
                            <h2 class="fz-50">Let's Build Something Great Together</h2>
                            <p class="fz-15 mt-10">Ready to elevate your online presence? 
                                Share your project ideas or questions below, and I'll 
                                get back to you quickly to discuss how we can achieve 
                                something exceptional together.</p>
                            <div class="phone fz-30 fw-600 mt-30 underline">
                                <a href="#0" class="text-primary">me@yahmed.dev</a>
                            </div>
                            <ul class="rest social-text d-flex mt-60">
                                <li class="mr-30">
                                    <a href="#0" class="hover-this"><span class="hover-anim">Github</span></a>
                                </li>
                                <li class="mr-30">
                                    <a href="#0" class="hover-this"><span class="hover-anim">Facebook</span></a>
                                </li>
                                <li class="mr-30">
                                    <a href="#0" class="hover-this"><span class="hover-anim">LinkedIn</span></a>
                                </li>
                                <li>
                                    <a href="#0" class="hover-this"><span class="hover-anim">Tiktok</span></a>
                                </li>
                            </ul>
                        </div>
                    </div>
                    <div class="col-lg-7 valign disabled">
                        <div class="full-width wow fadeIn" style={{visibility: "visible", animationName: "fadeIn"}}>
                            <form id="contact-form" method="post"  novalidate="true">

                                <div class="messages"></div>

                                <div class="controls row">

                                    <div class="col-lg-6">
                                        <div class="form-group mb-30">
                                            <input id="form_name" type="text" name="name" placeholder="Name" required="required" />
                                        </div>
                                    </div>

                                    <div class="col-lg-6">
                                        <div class="form-group mb-30">
                                            <input id="form_email" type="email" name="email" placeholder="Email" required="required" />
                                        </div>
                                    </div>

                                    <div class="col-12">
                                        <div class="form-group mb-30">
                                            <input id="form_subject" type="text" name="subject" placeholder="Subject" />
                                        </div>
                                    </div>

                                    <div class="col-12">
                                        <div class="form-group">
                                            <textarea id="form_message" name="message" placeholder="Message" rows="4" required="required"></textarea>
                                        </div>
                                        <div class="mt-30">
                                            <button type="submit" class="disabled">
                                                <span class="text">Send A Message</span>
                                            </button>
                                        </div>
                                    </div>

                                </div>
                            </form>
                        </div>
                    </div>
                </div>
            </div>
    </>
  )
}

export default Cform
